import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="app-background-image"></div>
    </div>
  );
}

export default App;